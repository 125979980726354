import React from "react";
import { ExcelRenderer, OutTable } from "react-excel-renderer";

import { useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { utils, writeFile } from "xlsx";

import GaugeChart from "react-gauge-chart";
import { useNavigate } from "react-router-dom";
// import FileUpload from "./DropFileUpload";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Icon } from "@iconify/react";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
const BulkUploadAnalysis = () => {
  //   const [analysisData, setAnalysisData] = useState({
  //     PatientID: "",
  //     Previous_Treatment: "No",
  //     Sex: "Female",
  //     Age: 14,
  //     Lineage: "BCP-ALL",
  //     Bulky_Disease: "No",
  //     Highest_presenting_WBC: 4900,
  //     NCI_Risk: "High",
  //     Prednisolone_Response: "Poor",
  //     CNS_Disease: "Yes",
  //     Cytogenetic_groups: "Non-High",
  //     Detailed_cytogenetics: "B-Other",
  //     ProvisionalRisk: "IR",
  //     RemissionStatus_EOI: "in remission",
  //     MRD_Status_EOI: "Positive",
  //     FinalRisk: "HR",
  //     PhaseOf_TW_abandonment: ".",
  //     Remission_Days: 48,
  //     Completion_Days: 950,
  //     currentStatusAtLastFollowUp: "Alive",
  //   });
  const chartStyle = {
    height: 250,
  };
  const navigate = useNavigate();
  const [relapseResult, setRelapseResult] = useState();
  const [state, setState] = useState([]);

  //   console.log(analysisData);
  //   const generalHandleValueChanges = (event, key) => {
  //     setAnalysisData((prev) => ({
  //       ...prev,
  //       [key]: event?.target?.value,
  //     }));
  //   };

  //   const onClickGetResponce = () => {
  //     const url = "http://15.206.74.59:8000/alls/api";
  //     const { PatientID, ...analysisDataupdtae } = analysisData;
  //     const data = {
  //       ...analysisDataupdtae,
  //     };

  //     axios
  //       .post(url, data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Include additional headers here if needed
  //         },
  //       })
  //       .then((response) => {
  //         console.log("Success:", response.data);
  //         setRelapseResult(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   };
  const fileHandler = (file) => {
    let fileObj = file;

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // console.log(resp.rows);
        setState({
          //   cols: resp.cols,
          Data: resp.rows,
        });
      }
    });
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    fileHandler(file);
    // if (file && file.size > 5 * 1024 * 1024) {
    //   FloatingNotifications("error", "File should be less than 5 MB");
    //   return;
    // }
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     // The result contains the base64 string
    //     fileHandler(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const onHandleExport = useCallback(async (header, data) => {
    let exportData = [];
    await data?.map((order) => {
      let mappedOrder = {};
      header.forEach((key, index) => {
        mappedOrder[key] = order[index];
      });
      exportData.push(mappedOrder);
    });

    console.log(exportData);
    const ws = utils.json_to_sheet(exportData);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");

    writeFile(wb, `download.xlsx`);
  });

  const onClickDownload = () => {
    axios
      .put(
        " /server/alls/api",
        { data: state },
        {
          headers: {
            "Content-Type": "application/json",
            // Include additional headers here if needed
          },
        }
      )
      .then((response) => {
        console.log("success");
        // console.log(response.data);
        let header = response.data.data[0];
        let data = response.data.data.slice(1);

        onHandleExport(header, data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onClickFn = () => {
    navigate("/secondPage");
  };

  return (
    <Box
      sx={{
        p: 2,

        "& select": {
          width: "100%",
        },
        "& input": {
          width: "100%",
        },
      }}
    >
      <Typography
        variant="h3"
        sx={{
          pb: 2,
          color: "#222",
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
        }}
        onClick={onClickFn}
        cursor="pointer"
      >
        <Icon icon="majesticons:arrow-left-line" /> Bulk Data Patient Analysis
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Paper elevation={2} sx={{ gridColumn: "span 3", p: 2 }}>
          <Typography
            variant="h4"
            sx={{ p: 1, color: "#000", fontWeight: 600 }}
          >
            Upload files{" "}
          </Typography>
          <Divider />
          <Box
            sx={{
              borderRadius: "8px",
              width: "100%",
              color: "#000",
              fontWeight: 500,
              fontFamily: "Inter",
              fontSize: "1.4rem",
              textTransform: "capitalize",
              height: "6rem",
              //   m: ,
            }}
          >
            {/* <FileUpload /> */}
            <input
              type="file"
              onChange={handleFileUpload}
              style={{
                width: "100%",
                borderRadius: "8px",
                padding: "1rem",
                backgroundColor: "#eee",
                margin: "1rem",
                height: "100%",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{ p: 1, color: "#000", fontWeight: 400, textAlign: "end" }}
          >
            *in csv or xlsx format only (should not exceed more than 200 mb){" "}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              background: "linear-gradient(180deg, #F7C17F 0%, #F5A849 100%)",
              color: "#000",
              fontWeight: 500,
              fontFamily: "Inter",
              fontSize: "1.4rem",
              mt: 1,
            }}
            onClick={onClickDownload}
          >
            Download
          </Button>
        </Paper>
        {/* <Paper elevation={2} sx={{ gridColumn: "span 3", p: 2 }}>
          ;c;;f
        </Paper> */}
      </Box>
    </Box>
  );
};

export default BulkUploadAnalysis;
