import React from "react";
import classNames from "classnames";
import Loader from "../images/components/loader.svg";
import "../css/button.scss";
import { Icon } from "@iconify/react";
<Icon icon="majesticons:arrow-right-line" />;
const ButtonComponent = (props) => {
  const {
    containerClassName,
    className,
    text,
    onClick,
    disabled,
    tabIndex,
    icon,
  } = props;
  return (
    <div className={classNames("buttonComponent", containerClassName ?? "")}>
      <button
        tabIndex={tabIndex ?? ""}
        className={className ?? ""}
        onClick={onClick ?? (() => {})}
        disabled={disabled ? true : false}
      >
        <div className="internalContainer">
          <div className="loader">
            <img src={Loader} alt="" className={disabled ? "rotate" : ""} />
          </div>
          {text}
          {icon === "right" && <Icon icon="majesticons:arrow-right-line" />}
        </div>
      </button>
    </div>
  );
};

export default ButtonComponent;
