import React from "react";
import Background from "../images/Firefly make an image for a cover page theme -blood cancer orange ribbon and computer digital tech-  1.png";
import "../css/home.scss";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation";
import ButtonComponent from "../components/ButtonComponent";
import { useWindowSize } from "@uidotdev/usehooks";

const Home = () => {
  const navigate = useNavigate();
  const size = useWindowSize();

  const onClickFn = () => {
    navigate("/secondPage");
  };
  return (
    <>
      <div className="home">
        <Navigation />
        <div className="backgroundImageContainer">
          <img alt="background" className="backgroundImage" src={Background} />
        </div>
        <div className="background-overlay">
          <div className="content-section">
            <h1 className="heading">
              Tool for relapse prediction and data analysis
            </h1>
            <p className="sub">
              <span>P</span>ediatric <span>R</span>elapse <span>P</span>
              rediction <span>A</span>nd <span>R</span>isk <span>E</span>
              valuation App for <span>A</span>cute <span>L</span>ymphloblastic{" "}
              <span>L</span>eukemia
            </p>
            <ButtonComponent
              text="Get Started"
              icon={"right"}
              onClick={onClickFn}
              containerClassName={size.width < 500 ? "fullComponent" : "s"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
