import * as React from "react";
import { useNavigate } from "react-router-dom";
import TopBarImg from "../images/pc_topbar_bg.png";

import "../css/navigation.scss";

// const navItems = ["Home", "About", "Contact"];

function Navigation(props) {
  // const { window } = props;
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  // const handleDrawerToggle = () => {
  //   setMobileOpen((prevState) => !prevState);
  // };
  const navigate = useNavigate();
  const onClickFn = () => {
    navigate("/");
  };
  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
  //     <Typography variant="h6" sx={{ my: 2 }}>
  //       MUI
  //     </Typography>
  //     <Divider />
  //     <List>
  //       {/* {navItems.map((item) =>  */}
  //       <ListItem disablePadding>
  //         <ListItemButton sx={{ textAlign: "center" }}>
  //           <ListItemText primary={"Business"} />
  //         </ListItemButton>
  //       </ListItem>
  //       <ListItem disablePadding>
  //         <ListItemButton sx={{ textAlign: "center" }}>
  //           <ListItemText primary={"Community"} />
  //         </ListItemButton>
  //       </ListItem>
  //       <ListItem disablePadding>
  //         <ListItemButton sx={{ textAlign: "center" }}>
  //           <ListItemText primary={"About Us"} />
  //         </ListItemButton>
  //       </ListItem>
  //       <ListItem disablePadding>
  //         <ListItemButton sx={{ textAlign: "center" }}>
  //           <ListItemText primary={"Careers"} />
  //         </ListItemButton>
  //       </ListItem>
  //     </List>
  //   </Box>
  // );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    // <Box sx={{ display: "flex", zIndex: "0" }}>
    //   <CssBaseline />
    //   <AppBar
    //     component="nav"
    //     sx={{
    //       backgroundColor: "transparent",
    //       height: "4.5rem",
    //       boxShadow: 0,
    //     }}
    //   >
    //     <Toolbar
    //       sx={{
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <IconButton
    //         color="inherit"
    //         aria-label="open drawer"
    //         edge="start"
    //         onClick={handleDrawerToggle}
    //         sx={{
    //           mr: 2,
    //           display: { sm: "none" },
    //           fontFamily: "typography.Inter",
    //           fontSize: "32px",
    //           fontStyle: "normal",
    //           fontWeight: 700,
    //           lineHeight: "normal",
    //           letterSpacing: "-0.64px",
    //           textTransform: "uppercase",
    //         }}
    //       >
    //         <MenuIcon />
    //       </IconButton>
    //       <IconButton
    //         sx={{
    //           hover: "none",
    //           height: "100%",
    //           m: 1,
    //           color: " #F7C17F",
    //           fontFamily: "typography.Inter",
    //           fontSize: "32px",
    //           fontStyle: "normal",
    //           fontWeight: 700,
    //           lineHeight: "normal",
    //           letterSpacing: "-0.64px",
    //           textTransform: "uppercase",
    //         }}
    //         onClick={onClickFn}
    //       >
    //         Prepare-ALL
    //       </IconButton>
    //     </Toolbar>
    //   </AppBar>
    //   <nav>
    //     <Drawer
    //       container={container}
    //       variant="temporary"
    //       open={mobileOpen}
    //       onClose={handleDrawerToggle}
    //       ModalProps={{
    //         keepMounted: true, // Better open performance on mobile.
    //       }}
    //       sx={{
    //         display: { xs: "block", sm: "none" },
    //         "& .MuiDrawer-paper": {
    //           boxSizing: "border-box",
    //           width: drawerWidth,
    //         },
    //       }}
    //     >
    //       {drawer}
    //     </Drawer>
    //   </nav>
    //   <Box component="main" sx={{ m: 5, backgroundColor: "basic.base2" }}></Box>
    // </Box>
    <div className="navigation">
      <div className="imgContainer">
        <img src={TopBarImg} alt="" />
      </div>
      <div className="navContent" onClick={onClickFn}>
        PREPARE-ALL
      </div>
    </div>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // window: PropTypes.func,
};

export default Navigation;
