import React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GaugeChart from "react-gauge-chart";
import { useNavigate } from "react-router-dom";
import "../css/singlePatientAnalysis.scss";
import { addDays, format } from "date-fns";
// import moment from "moment";

import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Icon } from "@iconify/react";
// import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
const SinglePatientAnalysis = () => {
  const [analysisData, setAnalysisData] = useState({
    PatientID: "",
    Previous_Treatment: "",
    Sex: "",
    Age: "",
    Lineage: "",
    Bulky_Disease: "",
    Highest_presenting_WBC: "",
    NCI_Risk: "",
    Prednisolone_Response: "",
    CNS_Disease: "",
    Cytogenetic_groups: "",
    Detailed_cytogenetics: "",
    ProvisionalRisk: "",
    RemissionStatus_EOI: "",
    MRD_Status_EOI: "",
    FinalRisk: "",
    PhaseOf_TW_abandonment: "",
    Remission_Date: "",
    Diagnosis_Date: "",
    // Remission_Days: "",
    // Completion_Days: "",
    currentStatusAtLastFollowUp: "",
  });
  // const [analysisData, setAnalysisData] = useState({
  //   PatientID: "",
  //   Previous_Treatment: "",
  //   Sex: "",
  //   Age: 0,
  //   Lineage: "",
  //   Bulky_Disease: "No",
  //   Highest_presenting_WBC: 0,
  //   NCI_Risk: "",
  //   Prednisolone_Response: "",
  //   CNS_Disease: "",
  //   Cytogenetic_groups: "",
  //   Detailed_cytogenetics: "",
  //   ProvisionalRisk: "",
  //   RemissionStatus_EOI: "",
  //   MRD_Status_EOI: "",
  //   FinalRisk: "",
  //   PhaseOf_TW_abandonment: "",
  //   Diagnosis_Date: "",
  //   Remission_Date: "",
  //   Completion_Date: "",
  //   Remission_Days: 0,
  //   Completion_Days: 0,
  //   currentStatusAtLastFollowUp: "",
  //   completionDateAvailable: "",
  // });
  const chartStyle = {
    height: 250,
  };
  const navigate = useNavigate();
  const [relapseResult, setRelapseResult] = useState();
  const [relapseStage, setRelapseStage] = useState("-");
  const [relapseDate, setRelapseDate] = useState(null);
  // console.log(analysisData);
  const [emptyList, setEmptyList] = useState([]);
  const generalHandleValueChanges = (event, key) => {
    setAnalysisData((prev) => ({
      ...prev,
      [key]: event?.target?.value,
    }));
  };

  console.log(analysisData);
  const basicValidation = (obj) => {
    for (let key in obj) {
      if (key === "PatientID") {
        continue;
      }
      if (obj[key] === "") {
        return false;
      }
    }
    return true;
  };

  function checkEmptyFields(obj) {
    const emptyFields = [];

    for (const key in obj) {
      if (key === "PatientID") continue;
      if (obj.hasOwnProperty(key)) {
        if (
          obj[key] === undefined ||
          obj[key] === null ||
          obj[key].toString().trim() === ""
        ) {
          emptyFields.push(key);
        }
      }
    }

    setEmptyList(emptyFields);
  }
  const onClickGetResponce = () => {
    checkEmptyFields(analysisData);
    let validation = basicValidation(analysisData);
    if (!validation) {
      toast.error("Please fill out all required fields");
      return;
    }
    const url = "/server/alls/api";
    // const url = "http://15.206.74.59:8000/alls/api";
    const {
      PatientID,
      Diagnosis_Date,
      Remission_Date,
      Completion_Date,
      PhaseOf_TW_abandonment,
      ...analysisDataupdate
    } = analysisData;
    let Remission_Days, Completion_Days, diffTime;
    if (!Remission_Date) {
      Remission_Days = 0;
    } else {
      diffTime = Math.abs(new Date(Remission_Date) - new Date(Diagnosis_Date));
      Remission_Days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    if (!Completion_Date) {
      Completion_Days = 0;
    } else {
      diffTime = Math.abs(new Date(Completion_Date) - new Date(Diagnosis_Date));
      Completion_Days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    // console.log(Diagnosis_Date, Remission_Date, Completion_Date);
    // console.log(Remission_Days, Completion_Days);
    const data = {
      ...analysisDataupdate,
      PhaseOf_TW_abandonment:
        PhaseOf_TW_abandonment === "Not Available"
          ? "."
          : PhaseOf_TW_abandonment,
      Remission_Days,
      Completion_Days,
    };

    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          // Include additional headers here if needed
        },
      })
      .then((response) => {
        const relapse_result = response.data.relapseScore;
        const relapse_Prediction = response.data.relapsePrediction;
        console.log("Success:", relapse_result);
        setRelapseResult(relapse_result);
        let relapse_stage;

        if (relapse_Prediction === 0) {
          relapse_stage = "-";
        } else if (relapse_Prediction === 1 || relapse_Prediction === 2) {
          relapse_stage = "Very Early";
        } else if (relapse_Prediction === 3) {
          relapse_stage = "Early";
        } else {
          relapse_stage = "Late";
        }
        setRelapseStage(relapse_stage);

        // console.log(predRelapseDate);
        // if ((relapseResult?.relapseScore ?? 0) !== 0) {
        //   predRelapseDate = format(
        //     addDays(new Date(Diagnosis_Date), response.data.relapseDays),
        //     "yyyy-MM-dd"
        //   );
        //   setRelapseDate(predRelapseDate);
        // } else {
        //   predRelapseDate = format(new Date(Diagnosis_Date), "yyyy-MM-dd");
        //   setRelapseDate(predRelapseDate);
        // }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onClickFn = () => {
    navigate("/secondPage");
  };

  return (
    <div className="singlePatientAnalysis">
      <Typography
        variant="h3"
        sx={{
          pb: 2,
          color: "#222",
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
        }}
        onClick={onClickFn}
        cursor="pointer"
      >
        <Icon icon="majesticons:arrow-left-line" /> Single Patient Analysis
      </Typography>
      <div className="mainContainer">
        <Paper elevation={2} sx={{ p: 2 }}>
          <Typography
            variant="h4"
            sx={{ my: 1, color: "#222", fontWeight: 600 }}
          >
            Input Fields
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "grid",
              rowGap: "1.5rem",
              columnGap: "2.5rem",
              gridTemplateColumns:
                "repeat(auto-fit,minmax(min(250px,100%),1fr))",
              mt: "1rem",
            }}
          >
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Patient ID
              </Typography>
              <input
                value={analysisData?.PatientID}
                onChange={(event) =>
                  generalHandleValueChanges(event, "PatientID")
                }
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  // color: "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  color: emptyList.includes("Highest_presenting_WBC")
                    ? "#f00"
                    : "#333",
                }}
              >
                Highest WBC Count*
              </Typography>
              <input
                type="number"
                value={analysisData?.Highest_presenting_WBC}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Highest_presenting_WBC")
                }
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Detailed_cytogenetics")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Detailed Cytogenetics*
              </Typography>
              <select
                value={analysisData?.Detailed_cytogenetics}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Detailed_cytogenetics")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="Not required">Not required</option>
                <option value="B-Other">B-Other</option>
                <option value="BCR-ABL1">BCR-ABL1</option>
                <option value="ETV6-RUNX1">ETV6-RUNX1</option>
                <option value="High Hyperdiploidy">High Hyperdiploidy</option>
                <option value="Hypodiploidy"> Hypodiploidy</option>
                <option value="KMT2A Rearranged">KMT2A Rearranged</option>
                <option value="KMT2A">KMT2A</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Previous_Treatment")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Previous Treatment*
              </Typography>
              <select
                value={analysisData?.Previous_Treatment}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Previous_Treatment")
                }
              >
                {/* <option value="">Select</option> */}
                <option value="">Select</option>

                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("NCI_Risk") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                NCI Rank*
              </Typography>
              <select
                value={analysisData?.NCI_Risk}
                onChange={(event) =>
                  generalHandleValueChanges(event, "NCI_Risk")
                }
              >
                <option value="">Select</option>

                <option value="High">High</option>
                <option value="Standard">Standard</option>
              </select>
            </Box>

            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("ProvisionalRisk")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Provisional Risk*
              </Typography>
              <select
                value={analysisData?.ProvisionalRisk}
                onChange={(event) =>
                  generalHandleValueChanges(event, "ProvisionalRisk")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="SR">SR</option>
                <option value="IR">IR</option>
                <option value="HR">HR</option>
                <option value="T">T</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Sex") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Sex*
              </Typography>
              <select
                value={analysisData?.Sex}
                onChange={(event) => generalHandleValueChanges(event, "Sex")}
              >
                {" "}
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Prednisolone_Response")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Prednisolone Response*
              </Typography>
              <select
                value={analysisData?.Prednisolone_Response}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Prednisolone_Response")
                }
              >
                {" "}
                <option value="">Select</option>
                {/* <option value="">Select</option> */}
                <option value="Good">Good</option>
                <option value="Poor">Poor</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("RemissionStatus_EOI")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Remission Status EOI*
              </Typography>
              <select
                value={analysisData?.RemissionStatus_EOI}
                onChange={(event) =>
                  generalHandleValueChanges(event, "RemissionStatus_EOI")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value=".">.</option>
                <option value="in remission">in remission</option>
                <option value="not in remission">not in remission</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Age") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Age*
              </Typography>
              <input
                value={analysisData?.Age}
                onChange={(event) => generalHandleValueChanges(event, "Age")}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("CNS_Disease") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                CNS Disease*
              </Typography>
              <select
                value={analysisData?.CNS_Disease}
                onChange={(event) =>
                  generalHandleValueChanges(event, "CNS_Disease")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("MRD_Status_EOI") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                MRD Status EOI*
              </Typography>
              <select
                value={analysisData?.MRD_Status_EOI}
                onChange={(event) =>
                  generalHandleValueChanges(event, "MRD_Status_EOI")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="Not Available">Not Available</option>
                <option value="Not Required">Not Required</option>
                <option value="Negative">Negative</option>
                <option value="Positive">Positive</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Lineage") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Lineage*
              </Typography>
              <select
                value={analysisData?.Lineage}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Lineage")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="BCP-ALL">BCP-ALL</option>
                <option value="T-ALL">T-ALL</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Cytogenetic_groups")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Cytogenetic Groups*{" "}
              </Typography>
              <select
                value={analysisData?.Cytogenetic_groups}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Cytogenetic_groups")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="High">High</option>
                <option value="Non-High">Non-High</option>
                <option value="Not Available">Not Available</option>
                <option value="Not Required">Not Required</option>
              </select>
            </Box>

            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("FinalRisk") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Final Risk*{" "}
              </Typography>
              <select
                value={analysisData?.FinalRisk}
                onChange={(event) =>
                  generalHandleValueChanges(event, "FinalRisk")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="SR">SR</option>
                <option value="IR">IR</option>
                <option value="HR">HR</option>
                <option value="T">T</option>
              </select>
            </Box>
            {/* <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
color: emptyList.includes("Highest_presenting_WBC")
                    ? "#f00"
                    : "#333",                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Completion Date Available{" "}
              </Typography>
              <select
                value={analysisData?.completionDateAvailable}
                onChange={(event) =>
                  generalHandleValueChanges(event, "completionDateAvailable")
                }
              >
                <option value="available">Available</option>
                <option value="not available">Not Available</option>
              </select>
            </Box> */}
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Diagnosis_Date") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Diagnosis Date*{" "}
              </Typography>
              <input
                type="date"
                value={analysisData?.Diagnosis_Date}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Diagnosis_Date")
                }
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Remission_Date") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Remission Date*{" "}
              </Typography>
              <input
                type="date"
                value={analysisData?.Remission_Date}
                disabled={
                  analysisData["RemissionStatus_EOI"] === "not in remission"
                }
                onChange={(event) =>
                  generalHandleValueChanges(event, "Remission_Date")
                }
              />
            </Box>
            {/* <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
color: emptyList.includes("Highest_presenting_WBC")
                    ? "#f00"
                    : "#333",                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Completion Date{" "}
              </Typography>
              <input
                type="date"
                value={analysisData?.Completion_Date}
                disabled={
                  analysisData.completionDateAvailable === "not available"
                }
                onChange={(event) =>
                  generalHandleValueChanges(event, "Completion_Date")
                }
              />
            </Box> */}
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("Bulky_Disease") ? "#f00" : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Bulky Disease*{" "}
              </Typography>
              <select
                value={analysisData?.Bulky_Disease}
                onChange={(event) =>
                  generalHandleValueChanges(event, "Bulky_Disease")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("PhaseOf_TW_abandonment")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Phase of TW/abandonment*{" "}
              </Typography>
              <select
                value={analysisData?.PhaseOf_TW_abandonment}
                onChange={(event) =>
                  generalHandleValueChanges(event, "PhaseOf_TW_abandonment")
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="Consolidation">Consolidation</option>
                <option value="Delayed Intensification">
                  Delayed Intensification
                </option>
                <option value="Interim Maintenance">Interim Maintenance</option>
                <option value="Maintenance">Maintenance</option>
                <option value="Induction">Induction</option>
                <option value="Post Induction">Post Induction</option>
                <option value="Not Available">Not Available</option>
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  my: 0.5,
                  fontSize: "1.4rem",
                  color: emptyList.includes("currentStatusAtLastFollowUp")
                    ? "#f00"
                    : "#333",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Current Status at Last Follow Up*{" "}
              </Typography>
              <select
                value={analysisData?.currentStatusAtLastFollowUp}
                onChange={(event) =>
                  generalHandleValueChanges(
                    event,
                    "currentStatusAtLastFollowUp"
                  )
                }
              >
                {" "}
                <option value="">Select</option>
                <option value="Alive">Alive</option>
                <option value="Dead">Dead</option>
                <option value="Lost to follow up">Lost to follow up</option>
              </select>
            </Box>
            {/* <Box></Box> */}
            <Button
              variant="contained"
              sx={{
                borderRadius: "8px",
                background: "linear-gradient(180deg, #F7C17F 0%, #F5A849 100%)",
                color: "#000",
                fontWeight: 500,
                fontFamily: "Inter",
                fontSize: "1.4rem",
                mt: 1,
              }}
              onClick={onClickGetResponce}
            >
              Predict Relapse
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "8px",
                background: "linear-gradient(180deg, #F7C17F 0%, #F5A849 100%)",
                color: "#000",
                fontWeight: 500,
                fontFamily: "Inter",
                fontSize: "1.4rem",
                mt: 1,
              }}
              // onClick={onClickGetResponce}
              disabled={true}
            >
              Add Patient
            </Button>
          </Box>
        </Paper>
        <Box
          sx={{
            // gridColumn: "span 2",
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            mb: "1rem",
          }}
        >
          <Paper elevation={2} sx={{ p: 1 }}>
            {" "}
            <Typography
              variant="h4"
              sx={{ p: 1, color: "#000", fontWeight: 600 }}
            >
              PREPARE-ALL Meter{" "}
            </Typography>
            <Divider />
            <GaugeChart
              id="gauge-chart3"
              nrOfLevels={5}
              style={chartStyle}
              percent={(relapseResult ?? 0 * 100) / 5}
              colors={["#FED7AA", "#C64400"]}
              textColor={"#000"}
              hideText={true}
            />
            <Typography
              variant="h4"
              sx={{
                p: 1,
                color: "#000",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              RISK SCORE: {relapseResult ?? ""}
            </Typography>
          </Paper>
          <Paper elevation={2} sx={{ p: 2 }}>
            {" "}
            <Typography
              variant="h4"
              sx={{ color: "#000", fontWeight: 600, mb: 1 }}
            >
              ALL Relapse Stage{" "}
            </Typography>
            <Divider />
            <Typography
              variant="h4"
              sx={{ p: 1, textAlign: "center", color: "#000", fontWeight: 600 }}
            >
              {relapseStage}
            </Typography>
            <Typography
              sx={{ textAlign: "end", color: "#000", fontWeight: 500 }}
            >
              *Based on Relapse confidence{" "}
            </Typography>
          </Paper>
          <Paper elevation={2} sx={{}}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0",
                width: "100%",
                background: "linear-gradient(180deg, #F7C17F 0%, #F5A849 100%)",
                color: "#000",
                fontWeight: 500,
                fontFamily: "Inter",
                fontSize: "1.4rem",

                textTransform: "capitalize",
              }}
            >
              <Icon icon="majesticons:arrow-down-line" />{" "}
              <Typography
                sx={{
                  textAlign: "end",
                  color: "#000",
                  fontWeight: 500,
                  fontSize: "1.4rem",
                }}
              >
                Generate Report
              </Typography>
            </Button>
          </Paper>
        </Box>
      </div>
    </div>
  );
};

export default SinglePatientAnalysis;
