import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../css/servicescomp.scss";
import i1 from "../images/i1.svg";
import i2 from "../images/i2.svg";
import i3 from "../images/i3.svg";
import i4 from "../images/i4.svg";
import i5 from "../images/i5.svg";
import i6 from "../images/i6.svg";

const ServicesComponent = () => {
  const navigate = useNavigate();

  const servicesData = [
    {
      image: i1,
      para: "Lorem ipsum dolor sit amet consectetur. Nunc arcu tristique non diam venenatis semper egestas et. Metus sit lectus nisl mollis eu. Auctor adipiscing elit orci mollis eu. Auctor adipiscing elit orci ultrices. ",
      title: "Single Patient Analysis",
      navigateLink: "/thirdPage",
    },
    {
      image: i2,
      para: "Lorem ipsum dolor sit amet consectetur. Nunc arcu tristique non diam venenatis semper egestas et. Metus sit lectus nisl mollis eu.",
      title: "Bulk Data Patient Analysis",
      navigateLink: "/bulkUploadPage",
    },
    {
      image: i3,
      para: "Lorem ipsum dolor sit amet consectetur. Nunc arcu tristique non diam venenatis semper egestas et. Metus sit lectus nisl mollis eu.",
      title: "MRD Analysis",
    },
    {
      image: i4,
      para: "Lorem ipsum dolor sit amet consectetur. Nunc arcu tristique non diam venenatis semper egestas et. Metus sit lectus nisl mollis eu. Auctor adipiscing elit orci mollis eu. Auctor adipiscing elit orci ultrices. ",
      title: "Visualizations",
    },
    {
      image: i5,
      para: "Lorem ipsum dolor sit amet consectetur. Nunc arcu tristique non diam venenatis semper egestas et. Metus sit lectus nisl mollis eu. Auctor adipiscing elit orci mollis eu. Auctor adipiscing elit orci ultrices. ",
      title: "Summary & Profiling",
    },
    {
      image: i6,
      para: "Lorem ipsum dolor sit amet consectetur. Nunc arcu tristique non diam venenatis semper egestas et. Metus sit lectus nisl mollis eu. Auctor adipiscing elit orci mollis eu. Auctor adipiscing elit orci ultrices. ",
      title: "Generated Reports",
    },
  ];

  // const [hoveredIndex, setHoveredIndex] = useState(null);

  // const handleMouseEnter = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleMouseLeave = () => {
  //   setHoveredIndex(null);
  // };
  const navigateFn = (link) => {
    if (!link) return;
    navigate(link);
  };
  return (
    <div className="services-container">
      <div class="heading">
        Various features and tools for relapse prediction and data analysis
      </div>
      <div class="services-card-container">
        {servicesData.map((each, index) => (
          <div
            className={`services-card ${index < 2 ? "clickable" : "inactive"}`}
            onClick={navigateFn.bind(null, each.navigateLink)}
          >
            <img src={each.image} alt="app" className="star-image" />

            <div class="services-title">{each.title}</div>
            {/* <div class="services-para">{each.para}</div> */}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ServicesComponent;
