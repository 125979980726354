import ThirdPage from "./pages/ThirdPage";
import Home from "./pages/Home";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServicesComponent from "./components/ServicesComponent";
import Navigation from "./components/Navigation";
import SecondPage from "./pages/SecondPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import BulkUploadPage from "./pages/BulkUploadPage";
import theme from "./theme";

function App() {
  return (
    <div className="App">
      <Router>
        <ToastContainer />
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/secondPage" element={<SecondPage />} />
            <Route path="/thirdPage" element={<ThirdPage />} />
            <Route path="/bulkUploadPage" element={<BulkUploadPage />} />
          </Routes>

          {/* <Home /> */}
          {/* <ServicesComponent /> */}
          {/* <ThirdPage /> */}
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
