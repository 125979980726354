import React from "react";
import backGroundImage from "../images/Firefly make an image for a cover page theme -blood cancer orange ribbon and computer digital tech-  1.png";
import Box from "@mui/material/Box";
import Navigation from "../components/Navigation";
import SinglePatientAnalysis from "../components/SinglePatientAnalysis";
import { useNavigate } from "react-router-dom";

const ThirdPage = () => {
  return (
    <div
    // sx={{
    //   width: "100%",
    //   minHeight: "100vh",
    //   overflow: "hidden",
    //   position: "relative",
    // }}
    // className="singlePatientAnalysis"
    >
      {/* <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "45px",
          background:
            "linear-gradient(90deg, rgba(12, 15, 32, 0.90) 12.29%, rgba(30, 30, 30, 0.10) 133.23%)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0,
        }}
      ></Box> */}
      {/* <img
        src={backGroundImage}
        // sx={{
        //   position: "absolute",
        //   top: 0,
        //   left: 0,
        //   width: "100%",
        //   height: "100%",
        //   backgroundImage: `url(${backGroundImage})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   zIndex: 0,
        // }}
        style={{
          width: "100%",
          height: "100%",
        }}
        alt="backgroundImage"
      /> */}

      <Navigation />
      <Box
        sx={{
          zIndex: 999,
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          width: "100%",
          height: "100%",
          // p: 1,
        }}
      >
        <SinglePatientAnalysis />
      </Box>
    </div>
  );
};

export default ThirdPage;
