import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "##F7C17F",
      light: "#42a5f5",
      dark: "#1565c0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E8E528",
    },
    basic: {
      base: "#000",
      base2: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter",

    fontFamily2: "Lato",
  },
});
theme = responsiveFontSizes(theme);

export default theme;
