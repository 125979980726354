import React from "react";
import backGroundImage from "../images/Firefly make an image for a cover page theme -blood cancer orange ribbon and computer digital tech-  1.png";
import Box from "@mui/material/Box";
import Navigation from "../components/Navigation";
import ServicesComponent from "../components/ServicesComponent";
const SecondPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        // minHeight: "100vh",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(90deg, rgba(12, 15, 32, 0.90) 12.29%, rgba(30, 30, 30, 0.10) 133.23%)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0,
        }}
      ></Box>
      {/* <img
        src={backGroundImage}
        // sx={{
        //   position: "absolute",
        //   top: 0,
        //   left: 0,
        //   width: "100%",
        //   height: "100%",
        //   backgroundImage: `url(${backGroundImage})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   zIndex: 0,
        // }}
        style={{
          width: "100%",
          height: "100%",
        }}
        alt="backgroundImage"
      /> */}

      <Navigation style={{ zIndex: 1 }} />
      <Box
        sx={{
          // zIndex: 999,
          position: "relative",
          // top: "10%",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          width: "100%",
          // height: "100%",
          // p: 1,
        }}
      >
        <ServicesComponent />
      </Box>
    </Box>
  );
};

export default SecondPage;
